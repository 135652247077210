define("ember-validated-form/templates/components/validated-input/types/-themes/uikit/radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rMkcMsKQ",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[41,[51,[28,[37,4],[[30,2],0],null]],[[[10,\"br\"],[12],[13]],[]],null],[1,\"\\n  \"],[10,\"label\"],[15,0,[29,[\"uk-form-label \",[52,[33,6],\"uk-text-success\"],\" \",[52,[33,7],\"uk-text-danger\"]]]],[12],[1,\"\\n    \"],[10,\"input\"],[14,0,\"uk-radio uk-margin-small-right\"],[15,\"checked\",[28,[37,4],[[33,8],[30,1,[\"key\"]]],null]],[15,2,[30,1,[\"key\"]]],[15,3,[36,9]],[15,1,[29,[[36,10],\"-\",[30,2]]]],[15,\"onclick\",[28,[37,11],[[30,0],[28,[37,12],[[28,[37,11],[[30,0],[33,13],[30,1,[\"key\"]]],null],[33,14]],null]],null]],[15,\"disabled\",[36,15]],[14,4,\"radio\"],[12],[13],[1,\"\\n    \"],[1,[30,1,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"\\n\"]],[\"option\",\"i\"],false,[\"each\",\"-track-array\",\"options\",\"unless\",\"eq\",\"if\",\"isValid\",\"isInvalid\",\"value\",\"name\",\"inputId\",\"action\",\"queue\",\"update\",\"setDirty\",\"disabled\"]]",
    "moduleName": "ember-validated-form/templates/components/validated-input/types/-themes/uikit/radio-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});