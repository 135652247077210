define("ember-validated-form/components/validated-input/-themes/uikit/hint", ["exports", "ember-validated-form/components/validated-input/hint"], function (_exports, _hint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hint.default.extend({
    classNames: ["uk-text-muted"]
  });

  _exports.default = _default;
});