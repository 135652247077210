define("ember-validated-form/components/validated-button/-themes/uikit/button", ["exports", "ember-validated-form/components/validated-button/button"], function (_exports, _button) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _button.default.extend({
    classNames: ["uk-button"],
    classNameBindings: ["style"],
    style: Ember.computed("type", function () {
      return this.type === "submit" ? "uk-button-primary" : "uk-button-default";
    })
  });

  _exports.default = _default;
});