define("ember-validated-form/components/validated-input/types/-themes/uikit/textarea", ["exports", "ember-validated-form/components/validated-input/types/textarea"], function (_exports, _textarea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _textarea.default.extend({
    classNames: ["uk-textarea"],
    classNameBindings: ["isValid:uk-form-success", "isInvalid:uk-form-danger"]
  });

  _exports.default = _default;
});