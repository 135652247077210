define("ember-validated-form/templates/components/validated-input/types/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IGCQDr4U",
    "block": "[[[10,\"input\"],[15,3,[36,0]],[15,1,[36,1]],[15,\"disabled\",[36,2]],[15,\"checked\",[36,3]],[15,\"onclick\",[28,[37,4],[[30,0],[28,[37,5],[[28,[37,4],[[30,0],[33,6]],[[\"value\"],[\"target.checked\"]]],[33,7]],null]],null]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n\"],[46,[33,9],null,null,null],[1,\"\\n\"]],[],false,[\"name\",\"inputId\",\"disabled\",\"value\",\"action\",\"queue\",\"update\",\"setDirty\",\"component\",\"labelComponent\"]]",
    "moduleName": "ember-validated-form/templates/components/validated-input/types/checkbox.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});