define("ember-validated-form/templates/components/validated-input/types/-themes/uikit/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pOrDfdly",
    "block": "[[[46,[33,1],null,[[\"class\"],[[28,[37,2],[[52,[33,4],\"uk-text-success\"],\" \",[52,[33,5],\"uk-text-danger\"]],null]]],[[\"default\"],[[[[1,\"  \"],[10,\"input\"],[14,0,\"uk-checkbox uk-margin-small-right\"],[15,3,[36,6]],[15,1,[36,7]],[15,\"disabled\",[36,8]],[15,\"checked\",[36,9]],[15,\"onclick\",[28,[37,10],[[30,0],[28,[37,11],[[28,[37,10],[[30,0],[33,12]],[[\"value\"],[\"target.checked\"]]],[33,13]],null]],null]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"component\",\"labelComponent\",\"concat\",\"if\",\"isValid\",\"isInvalid\",\"name\",\"inputId\",\"disabled\",\"value\",\"action\",\"queue\",\"update\",\"setDirty\"]]",
    "moduleName": "ember-validated-form/templates/components/validated-input/types/-themes/uikit/checkbox.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});