define("ember-validated-form/components/validated-input/types/-themes/bootstrap/radio-group", ["exports", "ember-validated-form/templates/components/validated-input/types/-themes/bootstrap/radio-group", "ember-validated-form/components/validated-input/types/radio-group"], function (_exports, _radioGroup, _radioGroup2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _radioGroup2.default.extend({
    layout: _radioGroup.default
  });

  _exports.default = _default;
});