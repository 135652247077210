define("ember-validated-form/templates/components/validated-input/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FRY6/bj7",
    "block": "[[[18,1,null],[1,[34,1]],[1,[52,[33,3],\" *\"]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"label\",\"if\",\"required\"]]",
    "moduleName": "ember-validated-form/templates/components/validated-input/label.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});