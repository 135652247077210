define("ember-validated-form/components/validated-button", ["exports", "ember-validated-form/-private/themed-component", "ember-validated-form/templates/components/validated-button"], function (_exports, _themedComponent, _validatedButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _validatedButton.default,
    type: "button",
    tagName: "",
    buttonComponent: (0, _themedComponent.default)("validated-button/button")
  });

  _exports.default = _default;
});