define("ember-file-upload/components/base-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fileQueue: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.queue) {
        var accept = this.accept,
            multiple = this.multiple,
            disabled = this.disabled,
            onFileAdd = this.onFileAdd;
        Ember.setProperties(this.queue, {
          accept: accept,
          multiple: multiple,
          disabled: disabled,
          onFileAdd: onFileAdd
        });
      }
    },
    queue: Ember.computed('name', {
      get: function get() {
        var queueName = this.name;

        if (queueName != null) {
          var queues = this.fileQueue;
          return queues.find(queueName) || queues.create(queueName);
        } else {
          return undefined;
        }
      }
    })
  });

  _exports.default = _default;
});