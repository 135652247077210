define("ember-validated-form/templates/components/validated-input/types/radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b4S/Lk4E",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[10,\"label\"],[12],[1,\"\\n    \"],[10,\"input\"],[15,\"checked\",[28,[37,3],[[33,4],[30,1,[\"key\"]]],null]],[15,2,[30,1,[\"key\"]]],[15,3,[36,5]],[15,1,[29,[[36,6],\"-\",[30,2]]]],[15,\"onclick\",[28,[37,7],[[30,0],[28,[37,8],[[28,[37,7],[[30,0],[33,9],[30,1,[\"key\"]]],null],[33,10]],null]],null]],[15,\"disabled\",[36,11]],[14,4,\"radio\"],[12],[13],[1,\"\\n    \"],[1,[30,1,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1,2]],null]],[\"option\",\"i\"],false,[\"each\",\"-track-array\",\"options\",\"eq\",\"value\",\"name\",\"inputId\",\"action\",\"queue\",\"update\",\"setDirty\",\"disabled\"]]",
    "moduleName": "ember-validated-form/templates/components/validated-input/types/radio-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});