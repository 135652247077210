define("ember-validated-form/components/validated-form", ["exports", "ember-validated-form/templates/components/validated-form"], function (_exports, _validatedForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PROP_ON_SUBMIT = "on-submit";
  var PROP_ON_INVALID_SUBMIT = "on-invalid-submit";

  var _default = Ember.Component.extend({
    tagName: "form",
    classNameBindings: ["submitted"],
    attributeBindings: ["autocomplete"],
    loading: false,
    submitted: false,
    layout: _validatedForm.default,
    validateBeforeSubmit: true,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.model && this.model.validate) {
        this.model.validate();
      }
    },
    submit: function submit() {
      var _this = this;

      this.set("submitted", true);
      var model = this.model;

      if (!model || !model.validate) {
        this.runCallback(PROP_ON_SUBMIT);
        return false;
      }

      model.validate().then(function () {
        if (!_this.element) {
          // We were removed from the DOM while validating
          return;
        }

        if (model.get("isInvalid")) {
          _this.runCallback(PROP_ON_INVALID_SUBMIT);
        } else {
          _this.runCallback(PROP_ON_SUBMIT);
        }
      });
      return false;
    },
    runCallback: function runCallback(callbackProp) {
      var _this2 = this;

      var callback = this.get(callbackProp);

      if (typeof callback !== "function") {
        return;
      }

      var model = this.model;
      this.set("loading", true);
      Ember.RSVP.resolve(callback(model)).finally(function () {
        if (!_this2.element) {
          // We were removed from the DOM while running on-submit()
          return;
        }

        _this2.set("loading", false);
      });
    }
  });

  _exports.default = _default;
});