define("ember-validated-form/components/validated-input/types/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "input",
    type: "text",
    attributeBindings: ["value", "placeholder", "type", "inputId:id", "name", "autofocus", "autocomplete", "disabled", "update:oninput", "setDirty:onblur"]
  });

  _exports.default = _default;
});