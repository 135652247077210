define("ember-validated-form/components/validated-input/types/-themes/bootstrap/input", ["exports", "ember-validated-form/components/validated-input/types/input"], function (_exports, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _input.default.extend({
    classNameBindings: ["isValid:is-valid", "isInvalid:is-invalid"],
    classNames: ["form-control"]
  });

  _exports.default = _default;
});