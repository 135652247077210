define("ember-one-way-select/templates/components/one-way-select/option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FVoddKLU",
    "block": "[[[10,\"option\"],[15,2,[52,[30,1],[28,[37,1],[[30,2],[30,1]],null],[30,2]]],[15,\"selected\",[28,[37,2],[[30,3],[30,2],[30,1],[30,4]],null]],[12],[1,\"\\n\"],[41,[48,[30,9]],[[[1,\"    \"],[18,9,null],[1,\"\\n\"]],[]],[[[41,[30,5],[[[1,\"    \"],[46,[30,5],null,[[\"option\",\"index\",\"groupIndex\"],[[30,2],[30,6],[30,7]]],null],[1,\"\\n\"]],[]],[[[41,[30,8],[[[1,\"    \"],[1,[28,[35,1],[[30,2],[30,8]],null]],[1,\"\\n\"]],[]],[[[41,[30,1],[[[1,\"    \"],[1,[28,[35,1],[[30,2],[30,1]],null]],[1,\"\\n\"]],[]],[[[41,[30,4],[[[1,\"    \"],[1,[28,[35,1],[[30,2],[30,4]],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,2]],[1,\"\\n  \"]],[]]]],[]]]],[]]]],[]]]],[]]],[13],[1,\"\\n\"]],[\"@optionValuePath\",\"@option\",\"@selected\",\"@optionTargetPath\",\"@optionComponent\",\"@index\",\"@groupIndex\",\"@optionLabelPath\",\"&default\"],false,[\"if\",\"get\",\"one-way-select/contains\",\"has-block\",\"yield\",\"component\"]]",
    "moduleName": "ember-one-way-select/templates/components/one-way-select/option.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});