define("ember-validated-form/components/validated-input/types/-themes/uikit/input", ["exports", "ember-validated-form/components/validated-input/types/input"], function (_exports, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _input.default.extend({
    classNames: ["uk-input"],
    classNameBindings: ["isValid:uk-form-success", "isInvalid:uk-form-danger"]
  });

  _exports.default = _default;
});