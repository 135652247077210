define("ember-validated-form/components/validated-input/-themes/uikit/render", ["exports", "ember-validated-form/templates/components/validated-input/-themes/uikit/render", "ember-validated-form/components/validated-input/render"], function (_exports, _render, _render2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _render2.default.extend({
    layout: _render.default,
    classNames: ["uk-margin"]
  });

  _exports.default = _default;
});