define("ember-validated-form/components/validated-input/-themes/bootstrap/label", ["exports", "ember-validated-form/components/validated-input/label"], function (_exports, _label) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _label.default.extend({});

  _exports.default = _default;
});