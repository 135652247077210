define("ember-validated-form/components/validated-input/types/textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "textarea",
    attributeBindings: ["value", "placeholder", "name", "inputId:id", "disabled", "autofocus", "rows", "cols", "autocomplete", "update:oninput", "setDirty:onblur"]
  });

  _exports.default = _default;
});