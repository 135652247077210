define("ember-validated-form/templates/components/validated-input/types/-themes/bootstrap/radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BjZJf48j",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"custom-control custom-radio\"],[12],[1,\"\\n    \"],[10,\"input\"],[15,0,[29,[\"custom-control-input \",[52,[33,4],\"is-valid\"],\" \",[52,[33,5],\"is-invalid\"]]]],[15,\"checked\",[28,[37,6],[[33,7],[30,1,[\"key\"]]],null]],[15,2,[30,1,[\"key\"]]],[15,3,[36,8]],[15,1,[29,[[36,9],\"-\",[30,2]]]],[15,\"onclick\",[28,[37,10],[[30,0],[28,[37,11],[[28,[37,10],[[30,0],[33,12],[30,1,[\"key\"]]],null],[33,13]],null]],null]],[15,\"disabled\",[36,14]],[14,4,\"radio\"],[12],[13],[1,\"\\n    \"],[10,\"label\"],[14,0,\"custom-control-label\"],[15,\"for\",[29,[[36,9],\"-\",[30,2]]]],[12],[1,[30,1,[\"label\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1,2]],null]],[\"option\",\"i\"],false,[\"each\",\"-track-array\",\"options\",\"if\",\"isValid\",\"isInvalid\",\"eq\",\"value\",\"name\",\"inputId\",\"action\",\"queue\",\"update\",\"setDirty\",\"disabled\"]]",
    "moduleName": "ember-validated-form/templates/components/validated-input/types/-themes/bootstrap/radio-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});