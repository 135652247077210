define("ember-validated-form/components/validated-button/-themes/bootstrap/button", ["exports", "ember-validated-form/components/validated-button/button"], function (_exports, _button) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _button.default.extend({
    classNames: ["btn"],
    classNameBindings: ["style"],
    style: Ember.computed("type", function () {
      return this.type === "submit" ? "btn-primary" : "btn-default";
    })
  });

  _exports.default = _default;
});