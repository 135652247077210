define("ember-validated-form/components/validated-input/error", ["exports", "ember-validated-form/templates/components/validated-input/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _error.default,
    tagName: "span"
  });

  _exports.default = _default;
});