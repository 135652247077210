define("ember-validated-form/components/validated-input/types/radio-group", ["exports", "ember-validated-form/templates/components/validated-input/types/radio-group"], function (_exports, _radioGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _radioGroup.default,
    tagName: ""
  });

  _exports.default = _default;
});