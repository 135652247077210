define("ember-validated-form/templates/components/validated-input/types/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DZyp7iPP",
    "block": "[[[1,[28,[35,0],null,[[\"value\",\"options\",\"optionLabelPath\",\"optionValuePath\",\"optionTargetPath\",\"name\",\"id\",\"update\",\"focusOut\",\"includeBlank\",\"disabled\",\"multiple\",\"promptIsSelectable\"],[[33,1],[33,2],[33,3],[33,4],[33,5],[33,6],[33,7],[33,8],[33,9],[33,10],[33,11],[33,12],[28,[37,13],[[33,14],false],null]]]]],[1,\"\\n\\n\"]],[],false,[\"one-way-select\",\"value\",\"options\",\"optionLabelPath\",\"optionValuePath\",\"optionTargetPath\",\"name\",\"inputId\",\"update\",\"setDirty\",\"includeBlank\",\"disabled\",\"multiple\",\"or\",\"promptIsSelectable\"]]",
    "moduleName": "ember-validated-form/templates/components/validated-input/types/select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});