define("ember-validated-form/templates/components/validated-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EPkowsxy",
    "block": "[[[18,1,[[28,[37,1],null,[[\"model\",\"loading\",\"input\",\"submit\"],[[33,2],[33,3],[50,\"validated-input\",0,null,[[\"model\",\"submitted\",\"validateBeforeSubmit\"],[[33,2],[33,5],[33,6]]]],[50,\"validated-button\",0,null,[[\"class\",\"type\",\"loading\",\"label\"],[[33,7],\"submit\",[33,3],\"Save\"]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"model\",\"loading\",\"component\",\"submitted\",\"validateBeforeSubmit\",\"submitClass\"]]",
    "moduleName": "ember-validated-form/templates/components/validated-form.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});