define("ember-get-config/index", ["exports", "@embroider/macros/runtime"], function (_exports, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var configModulePath = "".concat((0, _runtime.config)("/codebuild/output/src647542154/src/git-codecommit.us-east-1.amazonaws.com/v1/repos/nsf-etap-ui/node_modules/ember-cli-moment-shim/node_modules/ember-get-config").modulePrefix, "/config/environment");

  var _default = require(configModulePath).default;

  _exports.default = _default;
});