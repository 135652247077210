define("ember-validated-form/templates/components/validated-input/types/-themes/bootstrap/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bsD3dW/g",
    "block": "[[[10,\"input\"],[15,0,[29,[\"custom-control-input \",[52,[33,1],\"is-valid\"],\" \",[52,[33,2],\"is-invalid\"]]]],[15,3,[36,3]],[15,1,[36,4]],[15,\"disabled\",[36,5]],[15,\"checked\",[36,6]],[15,\"onclick\",[28,[37,7],[[30,0],[28,[37,8],[[28,[37,7],[[30,0],[33,9]],[[\"value\"],[\"target.checked\"]]],[33,10]],null]],null]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n\"],[46,[33,12],null,[[\"class\"],[\"custom-control-label\"]],null],[1,\"\\n\"]],[],false,[\"if\",\"isValid\",\"isInvalid\",\"name\",\"inputId\",\"disabled\",\"value\",\"action\",\"queue\",\"update\",\"setDirty\",\"component\",\"labelComponent\"]]",
    "moduleName": "ember-validated-form/templates/components/validated-input/types/-themes/bootstrap/checkbox.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});